import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import fourOhFourStyles from "../styles/404.module.css"

class NotFoundPage extends React.Component {
  render() {
    const headerText = "404"
    const subHeaderText = "Page Not Found"

    return (
      <Layout
        headerText={headerText}
        subHeaderText={subHeaderText}
        hideHeader={true}
      >
        <SEO title="404: Not Found" />
        <div className={fourOhFourStyles.container}>
          <div className={fourOhFourStyles.content}>
            <p>It looks like this page doesn't exist.</p>
            <p>
              Take a look at one of these pages, you may find what you have been
              looking for:
            </p>
            <ul className={fourOhFourStyles.pageList}>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/blog">BLOG</Link>
              </li>
              <li>
                <Link to="/visualizations">DATA VISUALIZATIONS</Link>
              </li>
              <li>
                <Link to="/maps">MAPS</Link>
              </li>
              <li>
                <Link to="/research">RESEARCH</Link>
              </li>
              <li>
                <Link to="/media">MEDIA</Link>
              </li>
              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>{" "}
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
